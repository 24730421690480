import React from "react"
import {Logo} from "../../atoms"
import style from "./headerLogoLeft.module.css"

interface HeaderLogoProps {
    solid?: any
}

export const HeaderLogoLeft: React.FC<HeaderLogoProps> = ({solid}) => {
    if(!solid)
        return (
            <div className={style.LogoContainer} >
                <Logo/>
            </div>
        )

    return (
        <div className={style.LogoContainer} >
            <Logo solid/>
        </div>
    )
}