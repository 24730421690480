import {graphql, useStaticQuery} from "gatsby";

import {getCurrentSystem} from "../api/config";
import {getPhaseForTestSystem} from "../utility/phase";

export const useMetadataPhase = () => {
    const {site} = useStaticQuery(
        graphql`
            query Phase {
                site(siteMetadata: {}) {
                    siteMetadata {
                        phase {
                            active
                            phaseNumber
                        }
                    }
                }
            }

        `
    )
    //Phase switch for test system
    if (getCurrentSystem() === 'test') {
        if (getPhaseForTestSystem() === '') {
            return {phaseNumber: 3}
        } else {
            switch (getPhaseForTestSystem()){
                case '1': return {phaseNumber: 1}
                case '2': return {phaseNumber: 2}
                case '3': return {phaseNumber: 3}
                case '4': return {phaseNumber: 4}
                default: return {phaseNumber: 2}
            }
        }
    } else {
        return site.siteMetadata.phase.find(phase => phase.active === true)
    }
}