import { Link } from "gatsby"
import React from "react"
import LogoFooter from "../../../images/buhler_logo_white.svg"
import { useCmsData } from "../../../utility/CmsDataProvider"
import { getCmsLanguage, getLanguage } from "../../../utility/language"
import { Loading } from "../../atoms"
import style from "./footer.module.css"

export const Footer = () => {
  const region = getLanguage()

  const { data, loading, error } = useCmsData()
  if (loading) {
    return <h2>Loading...</h2>
  } else if (error) {
    return <h2>Error while loading CMS...</h2>
  }
  data?.setLanguage(getCmsLanguage())
  data?.asModel
    .reffromreflist("footer", "6ztqv4t7gksfukhvryugob3xewtxpfvfx2bme")
    ?.setLanguage(getCmsLanguage())
  const disclaimerTitle = data?.asModel
    .reffromreflist("footer", "6ztqv4t7gksfukhvryugob3xewtxpfvfx2bme", true)
    ?.asModel.str("disclaimerTitle")
  const imprintTitle = data?.asModel
    .reffromreflist("footer", "6ztqv4t7gksfukhvryugob3xewtxpfvfx2bme", true)
    ?.asModel.str("imprintTitle")
  const privacyPolicyTitle = data?.asModel
    .reffromreflist("footer", "6ztqv4t7gksfukhvryugob3xewtxpfvfx2bme", true)
    ?.asModel.str("privacypolicyTitle")
  const footerLanguagefile = data?.asModel.reffromreflist(
    "languagefile",
    "ojntfqmwwlxzktmaysjxsjepcfvmfzzhnajjg"
  )
  footerLanguagefile?.setLanguage(getCmsLanguage())
  const mediaText =
    footerLanguagefile?.asModel
      .objlist("entries")
      .find(i => i.Key === "footer.media").Value || ""
  const supportText =
    footerLanguagefile?.asModel
      .objlist("entries")
      .find(i => i.Key === "footer.support").Value || ""
  const lang = getCmsLanguage()
  let mediaLink =
    "https://www.buhlergroup.com/content/buhlergroup/global/en/media.html"
  let supportLink = "/support/?region=en_GB"
  if (lang.search("de") !== -1) {
    mediaLink =
      "https://www.buhlergroup.com/content/buhlergroup/global/de/media.html"
    supportLink = "/support/?region=de_DE"
  } else if (lang.search("es") !== -1) {
    mediaLink =
      "https://www.buhlergroup.com/content/buhlergroup/global/es/media.html"
  }

  return (
    <div className={style.footerOuterContainer}>
      <div className={style.footerInnerContainer}>
        <div className={style.footerLeftContainer}>
          <a
            target="_blank"
            href="https://www.buhlergroup.com/content/buhlergroup/global/en/homepage.html"
          >
            <ul className={style.footerOuterList}>
              <li className={style.footerOuterListItem}>
                <img className={style.footerLogo} src={LogoFooter} />
              </li>
            </ul>
          </a>
        </div>
        <div className={style.footerMiddleContainer}>
          <a
            target="_blank"
            href="https://www.buhlergroup.com/content/buhlergroup/global/en/homepage.html"
            className={style.linkItems}
          >
            buhlergroup.com
          </a>
          <a
            target="_blank"
            className={style.linkItems}
            onClick={() => {
              window.open(`/imprint?region=${region}`)
            }}
          >
            {imprintTitle}
          </a>

          <a
            target="_blank"
            className={style.linkItems}
            onClick={() => {
              window.open(`/privacyPolicy?region=${region}`)
            }}
          >
            {privacyPolicyTitle}
          </a>

          <a
            target="_blank"
            className={style.linkItems}
            onClick={() => {
              window.open(`/cookiePolicy?region=${region}`)
            }}
          >
            {disclaimerTitle}
          </a>

          <a target="_blank" href={supportLink} className={style.linkItems}>
            {supportText}
          </a>
        </div>
        <div className={style.footerRightContainer}>
          <a target="_blank" href="https://twitter.com/buhler_group">
            <i className={"icofont-twitter " + style.iconStyle} />
          </a>
          <a target="_blank" href="https://www.linkedin.com/company/buhler">
            <i className={"icofont-linkedin " + style.iconStyle} />
          </a>
          <a target="_blank" href="https://www.facebook.com/buhlergroup">
            <i className={"icofont-facebook " + style.iconStyle} />
          </a>
          <a target="_blank" href="https://www.instagram.com/buhler_group/">
            <i className={"icofont-instagram " + style.iconStyle} />
          </a>
          <a target="_blank" href="https://www.youtube.com/user/BuhlerGroup">
            <i className={"icofont-youtube-play " + style.iconStyle} />
          </a>
        </div>
      </div>
    </div>
  )
}
