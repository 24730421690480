import {useEffect, useState} from 'react';

export const usePhaseFourSwitch = (): boolean => {
    const checkTime = (): boolean => {
        if (new Date() >= new Date(Date.UTC(2021, 2, 25, 16, 0, 0))) {

            return true
        } else {

            return false
        }
    }

    const [phaseFour, setPhaseFour] = useState<boolean>(checkTime())

    useEffect(() => {
        const interval = setInterval(() => {
            if (checkTime()) {
                setPhaseFour(true)
                clearInterval(interval)
            } else {
                setPhaseFour(false)
            }
        }, 5000)

        return () => {
            clearInterval(interval)
        }
    }, [])

    return phaseFour
}
