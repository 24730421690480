import { LoginFormData } from "../components/organisms/loginForm"
import { UserFormData } from "../components/organisms/registerFormik/UserFormData"
import { SalesData } from "../components/organisms/salesForm/SalesFormData"
import SSPContentVO from "../lib/sspcontentext/model/SSPContentVO"
import { transformLanguageForCms } from "../utility/language"
import { apiConfig } from "./config"
import { HttpService, HttpServiceToken, HttpToken } from "./httpTest"
import { UserData } from "./login/loginState"

const BASE_URL = apiConfig.baseUrl

export interface ActivateUserRequest {
  token: string
}

export interface ResetPasswordRequest {
  password: string
}

export interface RegisterEvent {
  id: string
}
export interface RequestPasswordResetRequest {
  email: string
}

export interface RegisterRequest extends UserFormData {
  language: string
}

export class UserService {
  public static register = async (
    data: RegisterRequest,
    translations: Map<string, string>,
    registerFormText: SSPContentVO
  ) => {
    try {
      const stringifiedData = JSON.parse(JSON.stringify({ ...data }))
      stringifiedData.title = UserService.getTitleTranslation(
        stringifiedData.title,
        data.language,
        registerFormText
      )

      return await HttpService.post(`${BASE_URL}/users`, stringifiedData)
    } catch (error) {
      throw new Error(
        error.response?.data?.error?.message || "Unknown error occured"
      )
    }
  }

  private static getTitleTranslation(
    title: string,
    languageKey: string,
    registerFromText: SSPContentVO
  ): string {
    registerFromText.setLanguage(transformLanguageForCms(languageKey))
    switch (title) {
      case "1":
        return registerFromText.asModel
          .objlist("entries")
          .find(i => i.Key === "register.male").Value
      case "2":
        return registerFromText.asModel
          .objlist("entries")
          .find(i => i.Key === "register.female").Value
      case "3":
        return registerFromText.asModel
          .objlist("entries")
          .find(i => i.Key === "register.other").Value
      default:
        return registerFromText.asModel
          .objlist("entries")
          .find(i => i.Key === "register.male").Value
    }
  }

  public static login = async (data: LoginFormData): Promise<UserData> => {
    try {
      const result = await HttpService.post<UserData>(
        `${BASE_URL}/users/login`,
        data
      )

      return result.data
    } catch (error) {
      throw new Error(
        error.response?.data?.error?.message || "Unknown error occured"
      )
    }
  }

  public static sales = async (data: SalesData) => {
    try {
      const result = await HttpService.post<UserData>(`${BASE_URL}/sales`, data)

      return result.data
    } catch (error) {
      throw new Error(
        error.response?.data?.error?.message || "Unknown error occured"
      )
    }
  }

  public static logout = () => {
    return HttpToken.post(`${BASE_URL}/users/logout`)
  }

  public static currentUser = () => {
    return HttpService.get(`${BASE_URL}/users/current`)
  }

  public static async requestPasswordReset(data: RequestPasswordResetRequest) {
    try {
      const result = await HttpService.post(
        `${BASE_URL}/users/password-reset`,
        data
      )

      return result.data
    } catch (e) {
      throw e
    }
  }

  public static async resetPassword(
    data: ResetPasswordRequest,
    user: UserData
  ) {
    try {
      await HttpServiceToken.post(
        `${BASE_URL}/users/password-reset/${data}`,
        user
      )
    } catch (e) {
      throw e
    }
  }

  public static async activate(data: string) {
    try {
      await HttpToken.post(`${BASE_URL}/users/confirm/${data}`)
    } catch (e) {
      throw e
    }
  }

  public static userRegisteredEvents = () => {
    return HttpService.get(`${BASE_URL}/events/participation`)
  }

  public static async registerEvent(data: string) {
    try {
      await HttpToken.post(`${BASE_URL}/events/${data}/register`)
    } catch (e) {
      throw e
    }
  }

  public static async cancelRegistration(data: string) {
    try {
      await HttpToken.post(`${BASE_URL}/events/${data}/unregister`)
    } catch (e) {
      throw e
    }
  }
}
