import { globalHistory } from "@reach/router"
import { DropdownMenu } from "cuenect-web-core"
import { navigate } from "gatsby-link"
import React, { useEffect, useState } from "react"
import { useScrollYPosition } from "react-use-scroll-position"
import { getActiveDay, getEventId } from "../../../utility/urlParameter"
import { usePhaseFourSwitch } from "../../../customeHooks/usePhaseFourSwitch"
import style from "./dropdownLanguage.module.css"

interface Language {
  languageKey: string
  languageText?: string
}

interface DropdownLanguageProps {
  solid?: any
}

export const DropdownLanguage = (props: DropdownLanguageProps) => {
  // read language key from url param, if not exists, navigate with default language
  const { location } = globalHistory
  const urlParameters = new URLSearchParams(location.search.substring(1))
  const region = urlParameters.get("region") || ""
  // TODO: set default region to user region
  const defaultRegion = "en_GB"
  const defaultRegionText = "EN"
  const phaseFour = usePhaseFourSwitch()
  // key value pairs, languageKey: languageText
  const regions = new Map([
    ["en_GB", "EN"],
    ["de_DE", "DE"],
    ["en_US", "EN"],
    ["zh_CN", "CN"],
    ["en_CN", "EN"],
  ])
  const currentRoute = location.pathname

  useEffect(() => {
    if (region === "") {
      setLanguage(defaultRegion)
    } else {
      setLanguage(region)
      // TODO: implement loading texts from cms
    }
  }, [location.search])

  const [showDropdown, setShowDropdown] = React.useState(false)
  const [language, setLanguageText] = useState<Language>({
    languageKey:
      regions.get(region) || regions.get(defaultRegion) || defaultRegionText,
  })

  const setLanguage = (languageKey: string) => {
    const languageText = regions.get(languageKey) || defaultRegionText
    let activeDay = ""
    let eventId = ""
    const queryParams = new URLSearchParams(window.location.search)
    let myToken = ""
    setLanguageText({ languageKey: languageText })
    if (urlParameters.get("day") !== null) {
      activeDay = `&day=${getActiveDay()}`
    }
    if (getEventId() !== "") {
      eventId = `&eventId=${getEventId()}`
    }
    if (queryParams.get("token") !== null) {
      myToken = `&token=${queryParams.get("token")}`
    }
    navigate(
      `${currentRoute}?region=${languageKey}${activeDay}${eventId}${myToken}`,
      { replace: true }
    )
  }

  let dropDownLabelClass = style.dropdownLabel
  let iconClass = style.Icon

  if (useScrollYPosition() < 1 && !props.solid) {
    dropDownLabelClass += `  ${style.landing}`
    iconClass += ` ${style.landing}`
  }

  const hide = () => setShowDropdown(false)

  const isHighlighted = (key: string) => {
    if (key === region) {
      return {
        textDecoration: "underline",
        color: "rgb(0, 155, 145)",
      }
    }
  }

  const phase3Dropdown = (
    <div className={style.innerDropdownContainer}>
      <div className={style.textRegion}>EMEA</div>
      <div
        className={style.dropdownItemContainer}
        onClick={() => {
          setLanguage("en_GB")
          hide()
        }}
      >
        <div className={style.textContainer} style={isHighlighted("en_GB")}>
          English
        </div>
      </div>
      <div
        className={style.dropdownItemContainer}
        onClick={() => {
          setLanguage("de_DE")
          hide()
        }}
      >
        <div className={style.textContainer} style={isHighlighted("de_DE")}>
          Deutsch
        </div>
      </div>
      <div className={style.textRegion}>Americas</div>
      <div
        className={style.dropdownItemContainer}
        onClick={() => {
          setLanguage("en_US")
          hide()
        }}
      >
        <div className={style.textContainer} style={isHighlighted("en_US")}>
          English
        </div>
      </div>
      <div className={style.textRegion}>Asia</div>
      <div
        className={style.dropdownItemContainer}
        onClick={() => {
          setLanguage("en_CN")
          hide()
        }}
      >
        <div className={style.textContainer} style={isHighlighted("en_CN")}>
          English
        </div>
      </div>
    </div>
  )

  const phase4Dropdown = (
    <div className={style.innerDropdownContainer}>
      <div
        className={style.dropdownItemContainer}
        onClick={() => {
          setLanguage("en_GB")
          hide()
        }}
      >
        <div className={style.textContainer} style={isHighlighted("en_GB")}>
          English
        </div>
      </div>
      <div
        className={style.dropdownItemContainer}
        onClick={() => {
          setLanguage("de_DE")
          hide()
        }}
      >
        <div className={style.textContainer} style={isHighlighted("de_DE")}>
          Deutsch
        </div>
      </div>
    </div>
  )

  const containerClass = [style.dropdownContainer]
  if (phaseFour === true) containerClass.push(style.phase4)

  return (
    <div className={style.OuterContainer}>
      <div
        className={style.HoverContainer}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <i className={iconClass}>
          <i className="icofont-globe" />
        </i>
        <DropdownMenu
          showDropdown={showDropdown}
          onClick={() => setShowDropdown(!showDropdown)}
          onOutsideClick={() => {
            console.log("outside")
            hide()
          }}
          style={{ display: "inline-flex" }}
        >
          <label className={dropDownLabelClass}>{language.languageKey}</label>
          <span className={containerClass.join(" ")}>
            {phaseFour === true ? phase4Dropdown : phase3Dropdown}
          </span>
        </DropdownMenu>
      </div>
    </div>
  )
}
