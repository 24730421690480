import React from "react";
import styles from "./buttonBuehler.module.css"

type props = {
    onClick?(): void,
    green?: boolean,
    white?: boolean
    overwriteStyle?: string,

}

export const ButtonBuehler: React.FC<props> = ({
                                                   children,
                                                   onClick,
                                                   overwriteStyle,
                                                   green,
                                                   white}
                                                   ) => {
    let styleClass: string = styles.green;
    if (green) {
        styleClass = styles.green
    } else if (white) {
        styleClass = styles.white
    } else if (overwriteStyle) {
        styleClass = overwriteStyle
    }

    return (
        <button onClick={onClick} className={styleClass}>
            {children}
        </button>
    )
}
