import { Link } from "gatsby"
import { navigate } from "gatsby-link"
import React from "react"
import { HeaderMenu } from ".."
import { UserMenuItem } from "../.."
import "../../../fonts/icofont.css"
import style from "./headerBurgerMenu.module.css"

interface HeaderBurgerMenuProps {
  solid?: any
  loginState: any
}

export class HeaderBurgerMenu extends React.Component<HeaderBurgerMenuProps> {
  solid: any
  isOpen: boolean
  scrollY: any
  loginState: any
  region: string

  constructor(props: HeaderBurgerMenuProps) {
    super(props)
    this.solid = props.solid
    this.loginState = props.loginState
    this.isOpen = false
    this.openMenu = this.openMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.scrollY = 0
  }

  openMenu() {
    this.isOpen = true
    this.forceUpdate()
  }

  closeMenu() {
    this.isOpen = false
    this.forceUpdate()
  }

  handleScroll() {
    this.scrollY = window.scrollY
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
    const urlParameters = new URLSearchParams(
      window.location.search.substring(1)
    )
    this.region = urlParameters.get("region") || ""
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  render() {
    let iconContainerStyle = style.iconContainer
    let menuContainerStyle = style.menuContainer
    let userName = null
    const menu = <HeaderMenu solid burger />

    const { user } = this.loginState.state
    const firstName = user?.person?.firstName
    const lastName = user?.person?.lastName

    let userControl = {}

    if (this.loginState.state.loggedIn) {
      userControl = (
        <a
          target="_blank"
          className={style.linkItem}
          onClick={() => {
            const urlParameters = new URLSearchParams(
              window.location.search.substring(1)
            )
            const region = urlParameters.get("region") || ""
            navigate(`/user?region=${region}`)
          }}
        >
          Logout / User
        </a>
      )
      userName = (
        <div className={style.userName}> {` ${firstName} ${lastName}`} </div>
      )
    } else {
      userControl = (
        <a
          target="_blank"
          className={style.linkItem}
          onClick={() => {
            const urlParameters = new URLSearchParams(
              window.location.search.substring(1)
            )
            const region = urlParameters.get("region") || ""
            navigate(`/loginForm?region=${region}`)
          }}
        >
          Login / Register
        </a>
      )
    }

    if (this.scrollY < 1 && !this.solid) {
      iconContainerStyle += " " + style.landing
    }

    if (this.isOpen) {
      menuContainerStyle += " " + style.open
    }

    const phaseFour = new Date() >= new Date(Date.UTC(2021, 2, 25, 17, 0, 0))
    const dropDownContainerClasses = [style.dropDownContainer]
    if (phaseFour === true) dropDownContainerClasses.push(style.phase4)

    return (
      <div>
        <div className={iconContainerStyle}>
          <i className="icofont-navigation-menu" onClick={this.openMenu}></i>
        </div>
        <div className={menuContainerStyle}>
          <div className={style.menu}>
            <div className={style.iconContainer}>
              <i className={"icofont-close"} onClick={this.closeMenu}></i>
            </div>

            {userName}

            {menu}

            <div className={style.burgerFooter}>
              <a
                target="_blank"
                href="https://www.buhlergroup.com/content/buhlergroup/global/en/homepage.html"
                className={style.linkItem}
              >
                buhlergroup.com
              </a>
              <a
                target="_blank"
                className={style.linkItem}
                onClick={() => {
                  const urlParameters = new URLSearchParams(
                    window.location.search.substring(1)
                  )
                  const region = urlParameters.get("region") || ""
                  navigate(`/imprint?region=${region}`)
                }}
              >
                Imprint
              </a>

              <a
                target="_blank"
                className={style.linkItem}
                onClick={() => {
                  const urlParameters = new URLSearchParams(
                    window.location.search.substring(1)
                  )
                  const region = urlParameters.get("region") || ""
                  navigate(`/privacyPolicy?region=${region}`)
                }}
              >
                Privacy Policy
              </a>

              <a
                target="_blank"
                className={style.linkItem}
                onClick={() => {
                  const urlParameters = new URLSearchParams(
                    window.location.search.substring(1)
                  )
                  const region = urlParameters.get("region") || ""
                  navigate(`/cookiePolicy?region=${region}`)
                }}
              >
                Cookie Policy
              </a>

              <Link
                className={style.linkItem}
                to={`/help?region=${this.region}`}
              >
                Welcome Desk
              </Link>

              {userControl}
            </div>

            <div className={dropDownContainerClasses.join(" ")}>
              <UserMenuItem
                link={"dummy"}
                img={<i className="icofont-globe" />}
                text={"EN"}
                isDropdown={true}
                solid={true}
              />
            </div>
          </div>
          <div className={style.offclick} onClick={this.closeMenu}></div>
        </div>
      </div>
    )
  }
}
