import { globalHistory } from "@reach/router";
import { navigate } from "gatsby-link";
import React from "react"
import "../../../fonts/icofont.css"
import style from "./style.module.css"


export function Loading() {


    return (
        <div className={style.loadingContainer}>
            <h1 className={`${style.loading} ${style.spacer}`}>Bühler Virtual World </h1>
            <section>
                <div className={`${style.loading} ${style.loading05}`}>
                    <span>L</span>
                    <span>O</span>
                    <span>A</span>
                    <span>D</span>
                    <span>I</span>
                    <span>N</span>
                    <span>G</span>
                </div>
            </section>
        </div>
    )
}