import React from "react"
import { useLoginState } from "../../../api/login/loginState"
import "../../../fonts/icofont.css"
import { UserMenuItem } from "../../atoms"
import style from "./headerUserMenu.module.css"

interface HeaderUserMenuProps {
  solid?: any
}

export const HeaderUserMenu = (props: HeaderUserMenuProps) => {
  const { state } = useLoginState()

  const { user } = state
  const firstName = user?.person?.firstName
  const lastName = user?.person?.lastName

  return (
    <div>
      <ul className={style.UserMenuContainer}>
        {/*  <UserMenuItem
                    link={"/user"}
                    img={<i className="icofont-user-alt-7"/>}
                    text={` ${firstName} ${lastName}`}
                    isDropdown={false}
                    isSigInBtn={true}
                    solid={props.solid}/>
                <UserMenuItem
                    link={"/help"}
                    text={"Welcome Desk"}
                    isDropdown={false}
                    solid={props.solid}/> */}
        <UserMenuItem
          link={"dummy"}
          img={<i className="icofont-globe" />}
          text={"EN"}
          isDropdown={true}
          solid={props.solid}
        />
      </ul>
    </div>
  )
}
