// gc21.buhler.cuenect.online | localhost | virtualworld.buhlergroup.com
const hostname = typeof window !== 'undefined' ? window.location.hostname : ''

const apiUri =
    hostname === 'localhost' || hostname === 'gc21.buhler.cuenect.online'
        ? 'https://gc21.buhler.cuenect.online'
        : 'https://virtualworld.buhlergroup.com'

const goApiUri = "https://go.cuenect.de"

export const apiConfig = {
    baseUrl: `${apiUri}/api/v1`,
    baseURLGo: `${goApiUri}/api/v1`,
    agenda: `${apiUri}/agenda`,
    register: `${apiUri}/registerFormik`,
    sales: `${apiUri}/api/v1/sales`,
    currentUser: `${apiUri}/api/v1/users/current`,
    getEvents: `${apiUri}/api/v1/events/participation`,
    getEvents2: `${apiUri}/api/v1/events/participation-2`,
    user: `${apiUri}/api/v1/users`,
    login: `${apiUri}/api/v1/users/login`,
    logout: `${apiUri}/api/v1/users/logout`,
    passwordReset: `${apiUri}/api/v1/users/password-reset/`,
    confirm: `${apiUri}/api/v1/users/confirm/`,
    appointmentSlots: `${apiUri}/api/v1/appointments/slots`,
    appointment: `${apiUri}/api/v1/appointments`,
    /*  agenda: `${apiUri}`*/
    chatUrl: 'https://chat.cuenect.de',
    testSession: 'https://meet.virtualworld.buhlergroup.com/testsession'
}
//Only for phase switch on test system
export function getCurrentSystem(): string {
    if (hostname === 'localhost' || hostname === 'gc21.buhler.cuenect.online') {
        return 'test'
    } else {
        return 'live'
    }
}