import { globalHistory } from "@reach/router"
import { Link, navigate } from "gatsby"
import React from "react"
import { useScrollYPosition } from "react-use-scroll-position"
import { useLoginState } from "../../../api/login/loginState"
import { DropdownLanguage } from "../../molecules"
import style from "./userMenuItem.module.css"

interface UserItemProps {
  img?: string | any
  text: string
  isDropdown: boolean
  isSigInBtn?: boolean
  link: string
  solid?: any
}

export const UserMenuItem = (props: UserItemProps) => {
  const {
    state: { loggedIn },
  } = useLoginState()

  let signInHeader = style.signInHeader
  let icon = style.Icon
  let userMenuLink = style.userMenuLink
  let dropdownLang = <DropdownLanguage solid />

  if (useScrollYPosition() < 1 && !props.solid) {
    signInHeader = style.signInHeader + " " + style.landing
    icon = style.Icon + " " + style.landing
    userMenuLink = style.userMenuLink + " " + style.landing
    dropdownLang = <DropdownLanguage />
  }

  if (props.isSigInBtn && !loggedIn) {
    return (
      <li className={style.listItem}>
        <span className={style.Container}>
          <button
            onClick={() => {
              const { location } = globalHistory
              const urlParameters = new URLSearchParams(
                location.search.substring(1)
              )
              const region = urlParameters.get("region") || ""
              navigate(`/loginForm?region=${region}`)
            }}
            className={signInHeader}
          >
            Sign In
          </button>
        </span>
      </li>
    )
  } else {
    const { location } = globalHistory
    const urlParameters = new URLSearchParams(location.search.substring(1))
    const region = urlParameters.get("region") || ""

    return (
      <li className={style.listItem}>
        <div className={style.Container}>
          {props.isDropdown ? (
            dropdownLang
          ) : (
            <div className={style.IconContainer}>
              <Link to={`${props.link}?region=${region}`} className={icon}>
                {props.img}
              </Link>
              <span>
                <Link
                  to={`${props.link}?region=${region}`}
                  className={userMenuLink}
                  activeClassName={style.activeLinkUserMenu}
                >
                  {props.text}
                </Link>
              </span>
            </div>
          )}
        </div>
      </li>
    )
  }
}
