import React from "react"

interface HelpProps {
    id: string
}

export class HelpChat extends React.Component<HelpProps> {

    componentDidMount() {
        // Include the Crisp code here, without the <script></script> tags
        // old id "7d0108a8-45c4-4cbe-992a-eddd46d4753c"

        window.$crisp = [];
        window.CRISP_WEBSITE_ID = this.props.id;
        

        (function() {
          var d = document;
          var s = d.createElement("script");

          s.src = "https://client.crisp.chat/l.js";
          s.async = 1;
          d.getElementsByTagName("head")[0].appendChild(s);
        })();
    };

    render() {
        return(<div></div>)
    }

}
