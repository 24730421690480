import {graphql, StaticQuery} from 'gatsby'
import React from 'react'
import {useMetadataPhase} from "../../../customeHooks/useMetadataPhase";
import {usePhaseFourSwitch} from "../../../customeHooks/usePhaseFourSwitch";
import {useCmsData} from "../../../utility/CmsDataProvider";
import {getCmsLanguage} from "../../../utility/language";
import {Loading, MenuItem} from '../../atoms'

interface HeaderMenuProps {
    solid?: any
    burger?: any
}

export const HeaderMenu = (props: HeaderMenuProps) => {

    const {phaseNumber} = useMetadataPhase()
    const phaseFour = usePhaseFourSwitch()

    const {data, loading, error} = useCmsData();
    if (loading) {
        return <h2>Loading...</h2>
    } else if (error) {
        return <h2>Error while loading CMS...</h2>
    }
    const titleList = data?.asModel.reffromreflist("languagefile", "4xu4rqpjpczfeozz6dogbsbealqf42lmqbdvy", true);
    titleList?.setLanguage(getCmsLanguage());
    const liveEventTitle = titleList?.asModel.objlist("entries").find(item => item.Key === 'header.event').Value;
    const agendaTitle = titleList?.asModel.objlist("entries").find(item => item.Key === 'header.agenda').Value;
    const showroomTitle = titleList?.asModel.objlist("entries").find(item => item.Key === 'header.showroom').Value;
    const breakoutTitle = titleList?.asModel.objlist("entries").find(item => item.Key === 'header.breakout').Value;

    return (
        <StaticQuery
            query={graphql`
        query MainMenu {
          site {
            siteMetadata {
              mainMenu {
                active
                name
                link
                phase
              }
            }
          }
        }
      `}

            render={data => {
                let itemTitle = "";
                const navData = [
                    ...data.site.siteMetadata.mainMenu.filter(
                        active => active.phase <= phaseNumber && active.active === true
                    ),
                ]

                return (
                    <ul>
                        {navData.map(({name, link, notLoggedInLink}, index) => {
                            if (phaseFour && name === 'Breakout Rooms') {
                                return
                            }
                            if (name === 'Agenda') {
                                itemTitle = agendaTitle;
                            } else if (name === 'Live-Event') {
                                itemTitle = liveEventTitle
                            } else if (name === 'Showroom') {
                                itemTitle = showroomTitle
                            } else if (name === 'Breakout Rooms') {
                                itemTitle = breakoutTitle
                            } else {
                                itemTitle = name
                            }

                            if (!props.solid && !props.burger) {
                                return (
                                    <MenuItem to={link} key={index}> {itemTitle} </MenuItem>
                                )
                            }

                            if (props.solid && !props.burger) {
                                return (
                                    <MenuItem solid to={link} key={index}> {itemTitle} </MenuItem>
                                )
                            }

                            if (!props.solid && props.burger) {
                                return (
                                    <MenuItem burger to={link} key={index}> {itemTitle} </MenuItem>
                                )
                            }

                            if (props.solid && props.burger) {
                                return (
                                    <MenuItem solid burger to={link} key={index}> {itemTitle} </MenuItem>
                                )
                            }

                        })}
                    </ul>
                )
            }}
        />
    )
}