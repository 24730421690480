import {defaultTheme, ThemeProvider} from 'cuenect-web-core';
import React, {useEffect, useState} from 'react';
import Cookies from 'universal-cookie';
import "../../../fonts/icofont.css"
import {useCmsData} from "../../../utility/CmsDataProvider";
import {getCmsLanguage} from "../../../utility/language";
import "./../../../App.css"
import styles from "./cookieBanner.module.css"

export const CookieBanner = () => {
    const cookies = new Cookies();
    const cookiePolicy = cookies.get("cookiePolicy");
    const [additionalClass, setAdditionalClass] = useState("")
    const [textMap] = useState(new Map<string, string>());
    const {data, loading} = useCmsData()
    const landingPageRefList = data?.asModel
        .reffromreflist('languagefile', 'ojntfqmwwlxzktmaysjxsjepcfvmfzzhnajjg');
    landingPageRefList?.setLanguage(getCmsLanguage());
    const entries = landingPageRefList?.asModel.objlist('entries');
    entries?.forEach((i: { Key: string, Value: string }) => {
        textMap.set(i.Key, i.Value);
    });
    const cookieText = textMap.get("footer.cookie")
    const cookiePolicyText = textMap.get("footer.gocookie")
    const cookieAccept = textMap.get("footer.cookieAccept")
    const cookieNess = textMap.get("footer.cookieAcceptNecessary")

    useEffect(() => {
        if (!cookiePolicy || (cookiePolicy !== "all" && cookiePolicy !== "necessary")) {
            setAdditionalClass(styles.cookieEnabled)
        } else {
            setAdditionalClass(styles.cookieDisabled);
        }
    }, [])

    function onAccept() {
        cookies.set("cookiePolicy", "all");
        setAdditionalClass("")
        setTimeout(makeInvisible, 1500);
    }

    function onDecline() {
        cookies.set("cookiePolicy", "necessary");
        setAdditionalClass("")
        setTimeout(makeInvisible, 1500);
    }

    function makeInvisible() {
        setAdditionalClass(styles.cookieDisabled)
    }

    if (loading) {
        return <h2>Loading</h2>
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <div className={styles.cookieOverlay + " " + additionalClass}>
                <div className={styles.cookieDialog}>
                    <h1> Cookie Settings </h1>
                    <div className={styles.left}>
                        {cookieText+ " "}
                        <a target="_blank" href="cookiePolicy">
                            {cookiePolicyText}
                        </a>
                    </div>
                    <div className={styles.right}>
                        <div
                            className={styles.agree}
                            onClick={() => onAccept()}>{cookieAccept} </div>
                        <div
                            className={styles.decline}
                            onClick={() => onDecline()}>{cookieNess} </div>
                    </div>
                    <div className={styles.filler}/>
                </div>
            </div>
        </ThemeProvider>
    )
}
