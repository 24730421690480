import { globalHistory } from "@reach/router"
import { Link } from "gatsby"
import $ from "jquery"
import React, { useLayoutEffect, useRef } from "react"
import { useScrollYPosition } from "react-use-scroll-position"
import { useLoginState } from "../../../api/login/loginState"
import "../../../fonts/icofont.css"
import { HeaderLogoLeft, HeaderMenu, HeaderUserMenu } from "../../molecules"
import { HeaderBurgerMenu } from "../../molecules/headerBurgerMenu"
import style from "./header.module.css"

interface HeaderProps {
  hideMenu?: boolean
  solid?: any

  getHeaderDiv?(headerDiv: HTMLDivElement): void
}

export const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const divRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef(null)

  // Will pass the outer div to parent
  useLayoutEffect(() => {
    if (divRef !== null && divRef.current && props.getHeaderDiv) {
      props.getHeaderDiv(divRef.current)
    }
    // get all navBar Elements

    setTimeout(() => {
      const navElements = $("span>a")
      const colorString = "-2px 6px 0px -3px #009B91"

      const storgeElement = window.localStorage.getItem("clickedElement")

      navElements.on("click", function (event: any) {
        const clickedElement = $(this).html()
        window.localStorage.setItem("clickedElement", clickedElement)
      })

      if (storgeElement) {
        for (let i = 0; i < navElements.length; i++) {
          if (
            navElements[i].innerText.split(" ").join("") ===
            storgeElement.split(" ").join("")
          ) {
            navElements[i].style.boxShadow = colorString
          }
          if (i === navElements.length - 1) {
            window.localStorage.setItem("clickedElement", "")
          }
        }
      } else {
        for (let i = 0; i < navElements.length; i++) {
          navElements[i].style.boxShadow = "none"
        }

        const rowUri = window.location.pathname
        var uri = rowUri.replaceAll("/", "")

        for (let i = 0; i < navElements.length; i++) {
          var partLink = navElements[i].href.split("/")[3]
          var finalLink = partLink.split("?")[0]
          if (uri === finalLink) {
            navElements[i].style.boxShadow = colorString
          }
        }
      }
    }, 150)
  }, [])

  const loginState = useLoginState()

  let containerClass = style.outerContainer
  let logo = <HeaderLogoLeft solid />
  let menu = <HeaderMenu solid />
  let userMenu = <HeaderUserMenu solid />
  let burgerMenu = <HeaderBurgerMenu solid loginState={loginState} />

  if (useScrollYPosition() < 1 && !props.solid) {
    containerClass += " " + style.landing
    logo = <HeaderLogoLeft />
    menu = <HeaderMenu />
    userMenu = <HeaderUserMenu />
    burgerMenu = <HeaderBurgerMenu loginState={loginState} />
  }

  const { location } = globalHistory
  const urlParameters = new URLSearchParams(location.search.substring(1))
  const region = urlParameters.get("region") || ""

  const header = (
    <div className={containerClass} ref={divRef}>
      <div className={style.leftContainer}>
        {/*  <div className={style.burgerContainer}>{burgerMenu}</div> */}
        <Link to={`/?region=${region}`}>{logo}</Link>
      </div>

      <div className={style.centerContainer} ref={headerRef}>
        {/* {menu} */}
      </div>

      <div className={style.rightContainer}>{userMenu}</div>
    </div>
  )

  if (props.solid) {
    return <div className={style.headerContainer}> {header} </div>
  } else {
    return header
  }
}
