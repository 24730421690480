import { globalHistory } from "@reach/router";

export const getAgendaFilter = (): string => {
    const {location} = globalHistory
    const urlParameters = new URLSearchParams((location.search.substring(1)))

    return urlParameters.get("filter") || ""
}

export const getActiveDay = (): number => {
    const {location} = globalHistory
    const urlParameters = new URLSearchParams((location.search.substring(1)))
    const activeDay = urlParameters.get("day") || '0'

    return +activeDay
}

export const getEventId = (): string => {
    const {location} = globalHistory
    const urlParameters = new URLSearchParams((location.search.substring(1)))

    return urlParameters.get("eventId") || ''
}