import {graphql, useStaticQuery} from "gatsby";
import React from "react";
import { useScrollYPosition } from 'react-use-scroll-position'
import style from './logo.module.css'

interface LogoProps {
    isSticky?: boolean
    solid?: any
}

interface LogoPathGraph {
    rgb: {
        publicURL: string
    },
    white: {
        publicURL: string
    }
}

function getLogoPath(overlay: boolean): string {
    const data: LogoPathGraph = useStaticQuery(
        graphql`
            query  {
                rgb: file(relativePath: {eq: "images/buhler_logo_RGB.svg"}) {
                    publicURL
                },
                white: file(relativePath: {eq: "images/buhler_logo_white.svg"}) {
                    publicURL
                }
            }
        `
    )

    if(overlay) {
        return data.rgb.publicURL;
    }

    return data.white.publicURL;
}

export const Logo: React.FC<LogoProps> = ({isSticky, solid}) => {

    const scrollY = useScrollYPosition();
    let overlayClass = style.brand + " " + style.brandOverlay;

    if(scrollY < 1 && !solid) {
        overlayClass += " " + style.landing;
    }

    return (
        <div className={style.imageContainer}>
        <img src={getLogoPath(false)} alt="Bühler" className={style.brand} onClick={() => {
            window.scrollTo(0, 0)
        }} />
        <img src={getLogoPath(true)} alt="Bühler" className={overlayClass} />
        </div>
    )
}
