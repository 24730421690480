import axios, { AxiosRequestConfig } from 'axios'

export class HttpService {
    public static async post<T = any>(
        url: string,
        data: object,
        config?: AxiosRequestConfig,
    ) {
        const result = await axios.post<T>(url, data, {...config, withCredentials: true})

        return result
    }

    public static async get<T = any>(url: string, config?: AxiosRequestConfig) {
        return axios.get<T>(url, {...config, withCredentials: true})
    }
}

export class HttpToken {
    public static async post<T = any>(
        url: string,
        config?: AxiosRequestConfig
    ) {
        const result = await axios.post<T>(url, {...config, withCredentials: true})

        return result
    }

    public static async get<T = any>(url: string, config?: AxiosRequestConfig) {
        return axios.get<T>(url, config)
    }
}

export class HttpServiceToken {
    public static async post<T = any>(
        url: string,
        data: object
    ) {
        const result = await axios.post<T>(url, data)

        return result
    }

    public static async get<T = any>(url: string, config?: AxiosRequestConfig) {
        return axios.get<T>(url, {...config, withCredentials: true})
    }
}