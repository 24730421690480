import { globalHistory } from "@reach/router";
import { GatsbyLinkProps, Link } from 'gatsby'
import React from 'react'
import { useScrollYPosition } from 'react-use-scroll-position'
import { customEvent } from "../../../utility/analyticx";
import style from "./menuItem.module.css"

export interface MenuItemProps<T> extends GatsbyLinkProps<T> {
    home?: boolean;
    solid?: any;
    burger?: any;
}

export function MenuItem<T>({ children, ref, ...rest }: MenuItemProps<T>) {
    const { to, solid, burger } = rest
    let menuClass = style.menuLink;

    if (burger) {
        menuClass = style.burgerLink;
    }

    if (useScrollYPosition() < 1 && !solid) {
        menuClass += " " + style.landing;
    }

    const { location } = globalHistory
    const urlParameters = new URLSearchParams((location.search.substring(1)))
    const region = urlParameters.get("region") || ""

    const trackMenuItem = () => {
        customEvent([
            'Navigation' as never,
            "Click" as never,
            `${children ? children.toString() : "Button"}` as never
        ])
    }

    return (
        <span>
            <Link className={menuClass}
                onClick={trackMenuItem}
                to={`${to}?region=${region}`}
                activeClassName={style.activeLinkMenuItem}
                partiallyActive={to !== '/'}
            >
                {children}
            </Link>
        </span>
    )
}